import styles from './hamburger.scss'
import {Theme, Tile} from "../../themes/models";
import {themeObserver, ThemeObserver} from "../../themes/observer";
import {Fader} from "../fader/fader";
import {getCustomerGroupService, ICustomerGroupService} from "../../services/group/customer-group";
import {TrackClickEvent} from "../../services/tracking/models";
import {getTrackingService} from "../../services/tracking/tracking-service";

export class Hamburger extends HTMLElement {

  private root: ShadowRoot;
  private menuVisible: boolean;
  private currentTheme: Theme;
  private oldTheme: Theme;
  private wasHidden: boolean;
  private cgs: ICustomerGroupService;

  constructor() {
    super();
    this.onMenuOpened = this.onMenuOpened.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.isMenuVisible = this.isMenuVisible.bind(this);
    this.onRequestThemeChange = this.onRequestThemeChange.bind(this);
    window.addEventListener('ny-request-theme-change', this.onRequestThemeChange);

    this.cgs = getCustomerGroupService();
  }

  keyboardToggleMenu(e: KeyboardEvent) {
    const menu = this.root.querySelector('.hamburger-menu') as HTMLElement;
    const isOpen: boolean = menu.classList.contains('active');
    const key: string = e.code.toLowerCase();

    if ((key === "space" || key === "enter") && menu) {
      e.preventDefault();
      isOpen ? this.closeMenu() : this.openMenu();
    }

    if (key === "escape" && isOpen) {
      e.preventDefault();
      this.closeMenu();
    }
  }

  connectedCallback() {
    this.querySelector('.gender-picker .item.women').addEventListener('click', () => this.cgs.setCustomerGroup('FEMALE'));
    this.querySelector('.gender-picker .item.men').addEventListener('click', () => this.cgs.setCustomerGroup('MALE'));
    this.setCustomerGroup();

    this.root = this.attachShadow({mode: 'open'})
    window.addEventListener('ny-menu-opened', this.onMenuOpened);
    this.menuVisible = false;
    this.root.innerHTML = `
      <style>${styles}</style>
      <div class="hamburger-menu" tabindex="0" aria-label="Toggle Menu">
        <svg>
            <use xlink:href="/img/icons/sprites.svg#hamburger"/>
        </svg>
      </div>
      <slot></slot>
    `;
    const hamburgerMenu = this.root.querySelector('.hamburger-menu');
    if (!hamburgerMenu) return;

    hamburgerMenu.addEventListener('click', this.openMenu.bind(this));
    hamburgerMenu.addEventListener('keydown', (e: KeyboardEvent) => this.keyboardToggleMenu(e));

    this.querySelectorAll('hamburger-menu .is-sub-menu').forEach(element => element.addEventListener('click', (event: MouseEvent) => {
      this.handleTracking(event.target as HTMLAnchorElement)
      this.toggleSubMenu(element);
    }));

    this.querySelectorAll('.item-group-1')?.forEach(element => element.addEventListener('click', (event: MouseEvent) => this.handleTracking(event.target as HTMLAnchorElement)));
  }

  handleTracking(eventTarget: HTMLAnchorElement) {
    const trackingLabel = eventTarget.href ? eventTarget.href : eventTarget.innerHTML;
    getTrackingService().trackClick(new TrackClickEvent({
      category: 'Hamburger-Menu',
      action: 'clicked',
      label: trackingLabel
    }));
  }

  disconnectedCallback() {
    window.removeEventListener('ny-menu-opened', this.onMenuOpened);
    window.removeEventListener('ny-request-theme-change', this.onRequestThemeChange);
  }

  toggleSubMenu(element: Element) {
    element.classList.toggle('active');
    element.querySelectorAll('.sub-menu-item')?.forEach(it => it.classList.toggle('active'));
  }

  isMenuVisible(): boolean {
    return this.menuVisible;
  }

  onMenuOpened(event) {
    const menu = event.detail.menu;
    if (!(menu instanceof Hamburger)) {
      if (this.menuVisible) {
        this.closeMenu();
      }
    }
  }

  setCustomerGroup() {
    const customerGroup = this.getUrlParam('gender') || this.cgs.getCustomerGroup() || "FEMALE";
    this.cgs.setCustomerGroup(customerGroup);

    switch (customerGroup) {
      case 'FEMALE':
        this.querySelector(`.menu .gender-picker .item.men`)?.classList.remove('selected');
        this.querySelector(`.menu .gender-picker .item.women`)?.classList.add('selected');
        break;
      case 'MALE':
        this.querySelector(`.menu .gender-picker .item.women`)?.classList.remove('selected');
        this.querySelector(`.menu .gender-picker .item.men`)?.classList.add('selected');
        break;
    }
  }

  private getUrlParam(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  onRequestThemeChange(event: CustomEvent) {
    if (event.detail.caller instanceof Hamburger || event.detail.caller instanceof ThemeObserver || event.detail.caller instanceof Fader) {
      this.oldTheme = this.currentTheme;
      this.currentTheme = event.detail.theme as Theme;
      if (this.currentTheme === Theme.RED) {
        this.currentTheme = Theme.DARK;
      }

      if (event.detail.caller instanceof Fader) {
        this.oldTheme = themeObserver.getCurrentTheme();
        const menu = document.querySelector('hamburger-menu') as Hamburger;
        if (menu.isMenuVisible()) {
          return
        }
        this.currentTheme = event.detail.theme as Theme;
      }

      if (event.detail.caller instanceof ThemeObserver) {
        if (event.detail.tile === Tile.RIGHT) {
          this.oldTheme = this.currentTheme;
          this.currentTheme = Theme.DARK;
        }
      }
      const svg = this.root.querySelector('.hamburger-menu svg');
      svg.setAttribute('class', this.currentTheme);
    }
  }

  openMenu() {
    const hamburgerElement = this.root.querySelector('.hamburger-menu') as HTMLElement;
    hamburgerElement.removeEventListener('click', this.openMenu);
    hamburgerElement.addEventListener('click', this.closeMenu);
    window.dispatchEvent(new CustomEvent("ny-menu-opened", {detail: {menu: this as Hamburger}}));
    this.oldTheme = themeObserver.getCurrentTheme();
    themeObserver.setTheme(this as Hamburger, Theme.RED);
    this.menuVisible = true;
    this.querySelector('.menu').classList.add('active');
    hamburgerElement.classList.add('active');
    this.querySelector('hamburger-menu .blur-backdrop').classList.add('active');
    this.toggleSVG(this.menuVisible);
    this.toggleScroll();
    this.querySelector('.blur-backdrop').addEventListener('click', this.closeMenu);
    this.wasHidden = document.querySelector('.fashion-header .logo')?.classList.contains('hide')
    if (this.wasHidden) {
      document.querySelector('.fashion-header .logo')?.classList.remove('hide');
    }
    window.addEventListener('ny-menu-closed', this.closeMenu);
    this.addEventListener('keydown', (e) => {
      const targetElement = e.target as HTMLElement;
      const key: string = e.code.toLowerCase();
      if (targetElement.classList.contains('is-sub-menu') && key === "enter") {
        this.toggleSubMenu(targetElement);
      }

      if (key === "escape") {
        this.closeMenu();
        hamburgerElement.focus();
      }
    })
  }

  closeMenu() {
    this.menuVisible = false;
    themeObserver.setTheme(this as Hamburger, this.oldTheme);
    this.querySelector('.menu').classList.remove('active');
    const hamburgerElement = this.root.querySelector('.hamburger-menu') as HTMLElement;
    hamburgerElement.classList.remove('active');
    this.querySelector('hamburger-menu .blur-backdrop').classList.remove('active');
    this.toggleSVG(this.menuVisible);
    hamburgerElement.addEventListener('click', this.openMenu);
    this.querySelector('.blur-backdrop').removeEventListener('click', this.closeMenu);
    hamburgerElement.removeEventListener('click', this.closeMenu);
    this.toggleScroll();
    if (this.wasHidden) {
      document.querySelector('.fashion-header .logo')?.classList.add('hide');
    }
    window.removeEventListener('ny-menu-closed', this.closeMenu);
  }

  toggleSVG(state?: boolean) {
    const svg = this.root.querySelector('.hamburger-menu svg') as SVGElement;
    const use = this.root.querySelector('.hamburger-menu svg use') as SVGUseElement;
    if (state) {
      svg.setAttribute('class', 'dark');
      use.setAttribute('xlink:href', '/img/icons/sprites.svg#hamburger-close');
    } else {
      svg.setAttribute('class', this.currentTheme);
      use.setAttribute('xlink:href', '/img/icons/sprites.svg#hamburger');
    }
  }

  toggleScroll() {
    if (this.menuVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'initial';
    }
  }
}

window.customElements.define('hamburger-menu', Hamburger)
